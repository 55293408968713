// import { Link } from "react-router-dom";
// import Icons from "../assets/icons";
// import { Button } from "../components/atoms/buttons";
// import Table from "../components/molecules/Table";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState, useCallback } from "react";
// import { fetchAllCustomers } from "../redux/slices/getAllCustomersSlice";
// import TableSkeleton from "../components/molecules/TableSkeleton";
// import Pagination from "../components/molecules/Pagination";
// import debounce from "lodash/debounce";

// export default function ViewCustomer() {
//   const { customers, loading, error } = useSelector((state) => state.customers);
//   const dispatch = useDispatch();

//   const [currentPage, setCurrentPage] = useState(1);
//   const [searchTerm, setSearchTerm] = useState("");

//   const itemsPerPage = 10;

//   const customerData = customers?.data?.customers;
//   const totalPages = customers?.data?.pagination?.totalPages || 1;

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((searchValue) => {
//       setCurrentPage(1);
//       dispatch(
//         fetchAllCustomers({ page: 1, limit: itemsPerPage, search: searchValue })
//       );
//     }, 300),
//     [dispatch, itemsPerPage]
//   );

//   useEffect(() => {
//     debouncedSearch(searchTerm);
//   }, [debouncedSearch, searchTerm]);

//   useEffect(() => {
//     dispatch(
//       fetchAllCustomers({
//         page: currentPage,
//         limit: itemsPerPage,
//         search: searchTerm,
//       })
//     );
//   }, [dispatch, currentPage, itemsPerPage]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const headers = ["Name", "Company", "Email", "Action"];

//   const data = customerData?.map((customer, index) => [
//     <div className="flex w-[25%] items-center gap-3">
//       <div className="text-[#313d31] text-xs font-normal">{customer?.name}</div>
//     </div>,
//     <div className="flex gap-1 items-center">
//       <Icons.CompanyIcon />
//       <div className="text-[#313d31] text-xs font-normal">
//         {customer?.company?.name}
//       </div>
//     </div>,
//     <div className="flex gap-1 items-center">
//       <Icons.EmailWhite />
//       <div className="text-[#313d31] text-xs font-normal">
//         {customer?.email}
//       </div>
//     </div>,
//     <Link
//       to={`/admin/customers/${customer?.id}?tab=information`}
//       className="text-[#50c878] text-xs font-medium underline leading-none"
//     >
//       View detail
//     </Link>,
//   ]);

//   return (
//     <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
//       <div className="flex justify-between items-center  mb-2 w-full ">
//         <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
//           View Customer
//         </h1>

//         <div className="flex items-center gap-2">
//           <input
//             type="text"
//             placeholder="Search..."
//             className="border border-gray-300 rounded-md px-2 text-sm h-10 leading-normal outline-none"
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//           <Button className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded">
//             Add Customer
//           </Button>
//         </div>
//       </div>
//       <div className="mt-8">
//         {loading ? (
//           <TableSkeleton rows={10} columns={6} />
//         ) : (
//           <div>
//             <Table headers={headers} data={data} length={data?.length} />
//             <div className="mb-10">
//               {totalPages > 1 ? (
//                 <Pagination
//                   currentPage={currentPage}
//                   totalPages={totalPages}
//                   onPageChange={handlePageChange}
//                 />
//               ) : null}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import { Link } from "react-router-dom";
import Icons from "../assets/icons";
import { Button } from "../components/atoms/buttons";
import Table from "../components/molecules/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { fetchAllCustomers } from "../redux/slices/getAllCustomersSlice";
import TableSkeleton from "../components/molecules/TableSkeleton";
import Pagination from "../components/molecules/Pagination";
import debounce from "lodash/debounce";

export default function ViewCustomer() {
  const { customers, loading, error } = useSelector((state) => state.customers);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 10;

  const customerData = customers?.data?.customers;
  const totalPages = customers?.data?.pagination?.totalPages || 1;
  //get isPaying from the param of the URLSearchParams
  const searchParams = new URLSearchParams(window.location.search);
  const isPaying = searchParams.get("isPaying");
  const time = searchParams.get("time");
  console.log("isPaying", isPaying);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      dispatch(
        fetchAllCustomers({
          page: currentPage,
          limit: itemsPerPage,
          search: searchValue,
          isPaying: isPaying,
          time,
        })
      );
    }, 300),
    [dispatch, currentPage, itemsPerPage]
  );

  useEffect(() => {
    if (searchTerm) debouncedSearch(searchTerm);
  }, [debouncedSearch, searchTerm]);

  useEffect(() => {
    if (!searchTerm)
      dispatch(
        fetchAllCustomers({
          page: currentPage,
          limit: itemsPerPage,
          search: searchTerm,
          isPaying: isPaying,
          time,
        })
      );
  }, [dispatch, currentPage, itemsPerPage, searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const headers = ["Name", "Company", "Email", "Action"];

  const data = customerData?.map((customer, index) => [
    <div className="flex w-[25%] items-center gap-3">
      <div className="text-[#313d31] text-xs font-normal">{customer?.name}</div>
    </div>,
    <div className="flex gap-1 items-center">
      <Icons.CompanyIcon />
      <div className="text-[#313d31] text-xs font-normal">
        {customer?.company?.name}
      </div>
    </div>,
    <div className="flex gap-1 items-center">
      <Icons.EmailWhite />
      <div className="text-[#313d31] text-xs font-normal">
        {customer?.email}
      </div>
    </div>,
    <Link
      to={`/admin/customers/${customer?.id}?tab=information`}
      className="text-[#50c878] text-xs font-medium underline leading-none"
    >
      View detail
    </Link>,
  ]);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center  mb-2 w-full ">
        <h1 className="text-[#1d201d] text-xl font-bold  leading-7">
          View Customer
        </h1>

        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 rounded-md px-2 text-sm h-10 leading-normal outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded">
            Add Customer
          </Button>
        </div>
      </div>
      <div className="mt-8">
        {loading ? (
          <TableSkeleton rows={10} columns={6} />
        ) : (
          <div>
            <Table headers={headers} data={data} length={data?.length} />
            <div className="mb-10">
              {totalPages > 1 ? (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
