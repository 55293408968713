import { Link } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import TableSkeleton from "../../molecules/TableSkeleton";

export default function OnBoardingInfo({ customerDetails, loading }) {
  const customerBasicInfo = customerDetails?.data?.customer;
  console.log(customerBasicInfo);
  const sizeMapping = {
    10: "1-10 employees",
    50: "10 - 50 employees",
    101: "50 - 100 employees",
    100: "100+ employees",
    200: "200+ employees",
  };

  const getTimeAgo = (createdAt) => {
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
    ];

    if (!createdAt) return "Unknown";

    const createdDate = new Date(createdAt);
    const seconds = Math.floor((new Date() - createdDate) / 1000);
    const interval = intervals.find((i) => seconds >= i.seconds);

    if (interval) {
      const count = Math.floor(seconds / interval.seconds);
      return `Almost ${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }

    return "Just now";
  };

  const createdAt = customerDetails?.data?.customer?.created_at;
  const timeAgo = getTimeAgo(createdAt);

  const headersCompany = [
    "Company Name",
    "Position",
    "Heard From",
    "Achievement Goals",
    "Lead Data Type",
    "Company Size",
    "Company URL",
  ];

  const dataCompany = [
    [
      <div className="flex w-[25%] items-center gap-1">
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.company?.name ?? "-"}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.position ?? "-"}
        </div>
      </div>,

      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.heard_from}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {customerBasicInfo?.achievement_goals?.map((item) => item) ?? "-"}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          {customerBasicInfo?.lead_data_type ?? "-"}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          {sizeMapping[customerBasicInfo?.company?.employees] ?? "-"}
        </div>
      </div>,

      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {" "}
          {customerBasicInfo?.company?.website ?? "-"}
        </div>
      </div>,
    ],
  ];
  return (
    <div className="w-full ">
      {loading ? (
        <TableSkeleton rows={1} columns={6} />
      ) : (
        <>
          <Table
            headers={headersCompany}
            data={dataCompany}
            length={dataCompany?.length}
          />
        </>
      )}
    </div>
  );
}
