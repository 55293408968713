import React, { useState } from "react";
import Sidebar from "../molecules/Stepsidebar";
import NameStep from "./steps/NameStep";
import CompanyNameStep from "./steps/CompanyNameStep";
import CompanyUrlStep from "./steps/CompanyUrlStep";
import CompanySizeStep from "./steps/CompanySizeStep";
import PositionStep from "./steps/PositionStep";
import AchieveStep from "./steps/AchieveStep";
import HowDidStep from "./steps/HowDidStep";
import { useSelector } from "react-redux";
import LeadType from "./steps/LeadType";

const OnboardingScreen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { userDetail, loading: userDetailLoading } = useSelector(
    (state) => state.userDetail
  );

  const isUserOnboard = userDetail?.data?.isOnboarded;
  //Redirect to dashboard
  if (isUserOnboard) {
    window.location.href = "/dashboard";
    return;
  }

  const steps = [
    { number: "01", text: "Name", component: NameStep },
    { number: "02", text: "Company name", component: CompanyNameStep },
    { number: "03", text: "Company URL", component: CompanyUrlStep },
    { number: "04", text: "Company size", component: CompanySizeStep },
    { number: "05", text: "Position", component: PositionStep },
    { number: "06", text: "Purpose", component: AchieveStep },
    {
      number: "07",
      text: "Type of Leads Data Required",
      component: LeadType,
    },
    { number: "08", text: "How did you hear about us?", component: HowDidStep },
  ];

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="md:flex block h-screen bg-white font-sans">
      <Sidebar currentStep={currentStep} steps={steps} />
      <div className="flex-1">
        <div className="mx-auto container px-[1rem] md:px-[4.5rem] pt-[18%] pb-[5rem]">
          {CurrentStepComponent ? (
            <CurrentStepComponent
              onNext={handleNext}
              onBack={handleBack}
              currentStep={currentStep}
            />
          ) : (
            <div>Step {currentStep + 1} component not implemented yet.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingScreen;
