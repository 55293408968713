import { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import { Button } from "../components/atoms/buttons";
import Table from "../components/molecules/Table";
import TableSkeleton from "../components/molecules/TableSkeleton";
import Pagination from "../components/molecules/Pagination";
import { GET, PATCH, POST, DELETE } from "../services/apiServices";
import BlacklistDomainPopup from "../components/molecules/BlocklistDomainPopup";
import { FiEdit, FiTrash2 } from "react-icons/fi";

export default function BlacklistDomains() {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const itemsPerPage = 10;

  const fetchDomains = async (page, search) => {
    try {
      setLoading(true);
      const response = await GET("/blacklist-domains/all", {
        page,
        limit: itemsPerPage,
        search,
      });
      setDomains(response.data.items);
      setTotalPages(response.data.pagination.totalPages);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddDomain = async (domainData) => {
    try {
      setIsSubmitting(true);
      await POST("/blacklist-domains/create", domainData);
      setShowPopup(false);
      fetchDomains(currentPage, searchTerm);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditDomain = async (domainData) => {
    try {
      setIsSubmitting(true);
      await PATCH(`/blacklist-domains/${selectedDomain.id}`, domainData);
      setShowPopup(false);
      setSelectedDomain(null);
      fetchDomains(currentPage, searchTerm);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteDomain = async (domainId) => {
    if (window.confirm("Are you sure you want to delete this domain?")) {
      try {
        setLoading(true);
        await DELETE(`/blacklist-domains/${domainId}`);
        fetchDomains(currentPage, searchTerm);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchDomains(currentPage, searchValue);
    }, 300),
    [currentPage]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      fetchDomains(currentPage, "");
    }
  }, [debouncedSearch, searchTerm, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEdit = (domain) => {
    setSelectedDomain(domain);
    setShowPopup(true);
  };

  const headers = ["Domain", "Created At", "Actions"];

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const data = domains?.map((domain) => [
    <div className="flex w-[40%] items-center">
      <div className="text-[#313d31] text-xs font-normal">{domain.domain}</div>
    </div>,
    <div className="text-[#313d31] text-xs font-normal">
      {formatDate(domain.createdAt)}
    </div>,
    <div className="flex gap-3">
      <button
        onClick={() => handleEdit(domain)}
        className="text-blue-500 hover:text-blue-700 text-xs font-medium flex items-center gap-1"
      >
        <FiEdit className="w-3.5 h-3.5" /> Edit
      </button>
      <button
        onClick={() => handleDeleteDomain(domain.id)}
        className="text-red-500 hover:text-red-700 text-xs font-medium flex items-center gap-1"
      >
        <FiTrash2 className="w-3.5 h-3.5" /> Delete
      </button>
    </div>,
  ]);

  return (
    <div className="w-full px-4 sm:px-6 lg:px-6 pt-6 bg-[#fbfdfb] min-h-screen">
      <div className="flex justify-between items-center mb-2 w-full">
        <h1 className="text-[#1d201d] text-xl font-bold leading-7">
          Blacklist Domains
        </h1>

        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Search domains..."
            className="border border-gray-300 rounded-md px-2 text-sm h-10 leading-normal outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            onClick={() => {
              setSelectedDomain(null);
              setShowPopup(true);
            }}
            className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded"
          >
            Add Domain
          </Button>
        </div>
      </div>

      <div className="mt-8">
        {loading ? (
          <TableSkeleton rows={10} columns={3} />
        ) : error ? (
          <div className="text-red-500">Error: {error}</div>
        ) : (
          <div>
            <Table headers={headers} data={data} length={data?.length} />
            <div className="mb-10">
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <BlacklistDomainPopup
          onClose={() => {
            setShowPopup(false);
            setSelectedDomain(null);
          }}
          onSubmit={selectedDomain ? handleEditDomain : handleAddDomain}
          isLoading={isSubmitting}
          initialDomain={selectedDomain}
        />
      )}
    </div>
  );
}
