import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { store } from "./redux/store";

import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { fetchUserDetail } from "./redux/slices/getUserDetailSlice";
import { POST } from "./services/apiServices";

// Import all your components here
import OnboardingScreen from "./components/organisms/OnboardingScreen";
import Dashboard from "./components/organisms/Dashboard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import CreatePassword from "./pages/CreatePassword";
import SavedSearches from "./pages/SavedSearches";
import PostExports from "./pages/PostExports";
import LoginLayout from "./components/organisms/LoginLayout";
import Layout from "./components/organisms/Layout";
import Home from "./pages/Home";
import Accounts from "./pages/Accounts";
import BuyCredits from "./pages/BuyCredits";
import AdminLayout from "./components/organisms/AdminLayout";
import ViewCustomer from "./pages/ViewCustomer";
import GdprList from "./pages/Gdpr";
import CustomDBSearches from "./pages/CustomDBSearches";
import CreateCustomer from "./pages/CreateCustomer";
import GdprDetail from "./pages/GdprDetail";
import CreateDBSearch from "./pages/CreateDBSearch";
import ManageUser from "./pages/ManageUser";
import Billing from "./pages/Billing";
import Integration from "./pages/Integration";
import CustomerDetail from "./pages/CustomerDetail";
import CreateSuppersion from "./pages/CreateSuppersion";
import CreateCustomePurchase from "./pages/CreateCustomePurchase";
import Invite from "./pages/Invite";
import ViewStats from "./pages/ViewStats"; // Add this import

import Spinner from "./components/atoms/Spinner"; // Use a spinner component
import "./index.css";
import Intercom from "./components/organisms/intercom/Intercom";
import BlacklistDomains from "./pages/BlackListDomain";

function App() {
  const { data } = useSelector((state) => state.auth);
  const { userDetail, loading: userDetailLoading } = useSelector(
    (state) => state.userDetail
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenParam = searchParams.get("token")?.replace(/\s/g, "+");
    if (tokenParam) {
      handleVerifyEmail(tokenParam);
    }
  }, []);

  // Function to verify the email with the token
  const handleVerifyEmail = async (token) => {
    try {
      const result = await POST("customer/verify-email", {
        email_confirmation_token: token,
      });

      if (result.success) {
        toast.success(result.message);
      }
    } catch (error) {
      toast.error("Verification failed. Please try again.");
    }
  };
  const dispatch = useDispatch();
  const token = data?.token;
  const isAdmin = userDetail?.data?.customer?.is_admin;
  const isCompanyAdmin = userDetail?.data?.customer?.is_company_admin;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      // setToken(token);
      dispatch(fetchUserDetail()).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [token, dispatch]);

  if (loading || userDetailLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  const PublicRoutes = () => (
    <Routes>
      {/* <Route path="/onboard" element={<OnboardingScreen />} /> */}
      <Route
        path="/"
        element={
          <LoginLayout>
            <Login />
          </LoginLayout>
        }
      />
      <Route
        path="/signup"
        element={
          <LoginLayout>
            <Signup />
          </LoginLayout>
        }
      />
      <Route
        path="/reset-password"
        element={
          <LoginLayout>
            <ResetPassword />
          </LoginLayout>
        }
      />
      <Route
        path="/create-password"
        element={
          <LoginLayout>
            <CreatePassword />
          </LoginLayout>
        }
      />
      <Route path="/invite/:id" element={<Invite />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );

  const AdminAndUserRoutes = () => (
    <Routes>
      {/* Admin Routes */}
      {isAdmin && (
        <>
          <Route
            path="/admin/customers"
            element={
              <AdminLayout>
                <ViewCustomer />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/blacklist-domains"
            element={
              <AdminLayout>
                <BlacklistDomains />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/gdprlist"
            element={
              <AdminLayout>
                <GdprList />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/db-searches"
            element={
              <AdminLayout>
                <CustomDBSearches />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/gdprlist/:id"
            element={
              <AdminLayout>
                <GdprDetail />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/createcustomer"
            element={
              <AdminLayout>
                <CreateCustomer />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/customers/:id"
            element={
              <AdminLayout>
                <CustomerDetail />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/createdbsearch"
            element={
              <AdminLayout>
                <CreateDBSearch />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/create-suppersionlist"
            element={
              <AdminLayout>
                <CreateSuppersion />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/create-custompurchase/:id"
            element={
              <AdminLayout>
                <CreateCustomePurchase />
              </AdminLayout>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminLayout>
                <ViewStats />
              </AdminLayout>
            }
          />
        </>
      )}
      {isCompanyAdmin && (
        <>
          <Route
            path="/manage-users"
            element={
              <Layout width={true}>
                <ManageUser />
              </Layout>
            }
          />
          <Route
            path="/billing"
            element={
              <Layout width={true}>
                <Billing />
              </Layout>
            }
          />
          <Route
            path="/integrations"
            element={
              <Layout width={true}>
                <Integration />
              </Layout>
            }
          />
        </>
      )}
      {/* User Routes (accessible by both admin and regular users) */}
      <Route path="/database" element={<Dashboard />} />
      <Route path="/onboard" element={<OnboardingScreen />} />
      <Route
        path="/saved-searches"
        element={
          <Layout>
            <SavedSearches />
          </Layout>
        }
      />
      <Route
        path="/past-exports"
        element={
          <Layout>
            <PostExports />
          </Layout>
        }
      />
      <Route
        path="/home"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/account-settings"
        element={
          <Layout>
            <Accounts />
          </Layout>
        }
      />
      <Route
        path="/buy-credits"
        element={
          <Layout width={true}>
            <BuyCredits />
          </Layout>
        }
      />
      {/* Redirect based on user type */}
      <Route
        path="*"
        element={<Navigate to={isAdmin ? "/database" : "/database"} replace />}
      />
    </Routes>
  );

  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Intercom />
        {!token ? <PublicRoutes /> : <AdminAndUserRoutes />}
      </Router>
    </div>
  );
}

export default App;
