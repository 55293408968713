import React, { useState, useEffect } from "react";
import { Button } from "../atoms/buttons";
import Spinner from "../atoms/Spinner";

const BlacklistDomainPopup = ({
  onClose,
  onSubmit,
  isLoading,
  initialDomain = null, // Pass this when editing an existing domain
}) => {
  const [formValues, setFormValues] = useState({
    domain: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialDomain) {
      setFormValues({
        domain: initialDomain.domain || "",
      });
    }
  }, [initialDomain]);

  const validate = () => {
    let errors = {};

    if (!formValues.domain.trim()) {
      errors.domain = "Domain cannot be empty.";
    } else if (!isValidDomain(formValues.domain)) {
      errors.domain = "Please enter a valid domain name.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidDomain = (domain) => {
    // Basic domain validation regex
    const domainRegex =
      /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    return domainRegex.test(domain);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));

    // Clear error for the field being updated
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    onSubmit({
      domain: formValues.domain.toLowerCase().trim(),
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4">
          {initialDomain ? "Edit Domain" : "Add Domain"}
        </h2>

        <div className="mb-4">
          <label className="block mb-2">Domain Name</label>
          <input
            type="text"
            name="domain"
            value={formValues.domain}
            onChange={handleInputChange}
            className={`w-full p-2 border rounded ${
              errors.domain ? "border-red-500" : ""
            }`}
            placeholder="example.com"
          />
          {errors.domain && (
            <p className="text-red-500 text-sm mt-1">{errors.domain}</p>
          )}
        </div>

        <div className="flex justify-end">
          <Button onClick={onClose} className="mr-2 bg-gray-300 text-black">
            Cancel
          </Button>
          <Button
            className="bg-green-500 text-white disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? <Spinner /> : initialDomain ? "Update" : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlacklistDomainPopup;
