import Icons from "../assets/icons";

// menuItems.js

export const optionMenuItems = [
  {
    name: "Job Title",
    icon: `${process.env.PUBLIC_URL}/images/searchOption/job.png`,
  },
  {
    name: "Location",
    icon: `${process.env.PUBLIC_URL}/images/searchOption/location.png`,
  },
  {
    name: "Industry",
    icon: `${process.env.PUBLIC_URL}/images/searchOption/industry.png`,
  },
  {
    name: "Company Name",
    icon: `${process.env.PUBLIC_URL}/images/searchOption/company.png`,
  },
  {
    name: "Additional Filters",
    icon: `${process.env.PUBLIC_URL}/images/searchOption/filter.png`,
  },
];
// menuItems.js
export const sidebarItems = [
  {
    type: "customer",
    label: "List",
    labelMobile: "",
    items: [
      {
        name: "Build a List",
        url: "/dashboard",
        icon: <Icons.SearchIcon />,
      },
    ],
  },
  {
    type: "menu",
    label: "Your Account Menu",
    labelMobile: "Menu",
    items: [
      {
        name: "Home",
        url: "/home",
        icon: <Icons.Home />,
      },
      {
        name: "Past Exports",
        url: "/past-exports",
        icon: <Icons.PastExports />,
      },
      {
        name: "Saved Searches",
        url: "/saved-searches",
        icon: <Icons.SaveSearch />,
      },
      {
        name: "Account Settings",
        url: "/account-settings",
        icon: <Icons.AccountSettings />,
      },
      {
        name: "Buy Credits",
        url: "/buy-credits",
        icon: <Icons.BuyCredits />,
      },
      // {
      //   name: "Request Data",
      //   url: "/buy-credits",
      //   icon: <Icons.PastExports />,
      // },
    ],
  },

  {
    type: "admin",
    label: "Company Admin Menu",
    labelMobile: "Admin",
    items: [
      {
        name: "Manage Users",
        url: "/manage-users",
        icon: <Icons.ManageUser />,
      },
      {
        name: "Billing",
        url: "/billing",
        icon: <Icons.Billings />,
      },
      {
        name: "Integrations",
        url: "/integrations",
        icon: <Icons.Integration />,
      },
    ],
  },
];

// admin menu
// menuItems.js
export const adminMenu = [
  {
    type: "admin",
    label: "Customer",
    labelMobile: "Customer",
    items: [
      {
        name: "View Stats",
        url: "/admin",
        icon: Icons.chartIcon ? <Icons.chartIcon /> : <Icons.eyeIcon />,
      },
      {
        name: "View Customer",
        url: "/admin/customers",
        icon: <Icons.eyeIcon />,
      },
      {
        name: "Create Customer",
        url: "/admin/createcustomer",
        icon: <Icons.addIcon />,
      },
      {
        name: "Blacklisted Domains",
        url: "/admin/blacklist-domains",
        icon: <Icons.DomainIcon color="#000000" />,
      },
    ],
  },
  // {
  //   type: "admin",
  //   label: "GDPR Delete Request",
  //   labelMobile: "GDPR ",
  //   items: [
  //     {
  //       name: "View GDPR ",
  //       url: "/admin/gdprlist",
  //       icon: <Icons.eyeIcon />,
  //     },
  //   ],
  // },
  // {
  //   type: "admin",
  //   label: "Custom Database Searches",
  //   labelMobile: "CDB Searches",
  //   items: [
  //     {
  //       name: "View Custom Database",
  //       url: "/admin/db-searches",
  //       icon: <Icons.eyeIcon />,
  //     },
  //     {
  //       name: "Create Custom Database",
  //       url: "/admin/createdbsearch",
  //       icon: <Icons.addIcon />,
  //     },
  //   ],
  // },
  // {
  //   type: "admin",
  //   label: "Data",
  //   labelMobile: "Data",
  //   items: [
  //     {
  //       name: "Delete Data",
  //       url: "",
  //       icon: <Icons.eyeIcon />,
  //     },
  //   ],
  // },
];
