import React from "react";
import SignupForm from "../components/organisms/signup/SignupForm";
import LeadGenration from "../components/organisms/login/LeadGenration";
const Signup = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-5  w-full min-h-[82vh]  md:mt-10">
      <SignupForm />
      <LeadGenration />
    </div>
  );
};

export default Signup;
