import React, { useState } from "react";
import { Input } from "../../atoms/input";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../atoms/buttons";
import { login } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Spinner from "../../atoms/Spinner";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { loading, error, success } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleInputChange = (name, value) => {
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!email.trim()) {
      newErrors.email = "This field is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "This field is invalid";
    }

    if (!password.trim()) {
      newErrors.password = "This field is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();

    if (validateForm()) {
      setIsLoading(true);

      try {
        const result = await dispatch(
          login({
            email,
            password,
          })
        );

        if (result.meta.requestStatus === "fulfilled") {
          const data = result.payload;
          const checkOnboard = data?.isOnboarded;

          if (checkOnboard === false) {
            navigate("/onboard");
          } else if (checkOnboard === true) {
            navigate("/dashboard");
          }

          const token = data?.token;
          localStorage.setItem("userToken", token);
          toast.success("Logged in successfully!");
          setEmail("");
          setPassword("");
        } else {
          toast.error(
            result.payload?.message ||
              "Login failed. Please check your credentials."
          );
        }
      } catch (err) {
        console.error("Login failed:", err);
        toast.error(
          err.response?.data?.message || "Login failed. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="lg:w-1/2 max-w-md w-full flex flex-col items-start mx-auto px-5">
      <h1 className="md:text-start md:items-start text-start text-[#1d201d] lg:text-6xl text-3xl font-bold leading-[68px] w-full">
        Login
      </h1>
      <div className="text-[#313d31] text-xl font-normal my-4 leading-7">
        Please login using your email and password.
      </div>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-col items-start align-baseline w-full">
          <label htmlFor="Email">Email address</label>
          <Input
            type="email"
            id="Email"
            placeholder="name@gmail.com"
            value={email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={errors.email}
            className="!w-full text-[#313d31]"
          />
        </div>

        <div className="flex flex-col items-start align-baseline w-full mt-4">
          <label htmlFor="Password">Password</label>
          <Input
            type="password"
            id="Password"
            placeholder="Your password"
            value={password}
            onChange={(e) => handleInputChange("password", e.target.value)}
            error={errors.password}
            className="!w-full mb-1 text-[#313d31]"
          />
          <div className="flex justify-end ml-auto">
            <Link
              to="/reset-password"
              className="text-[#313d31] text-sm font-medium leading-tight w-full"
            >
              Forgot password?
            </Link>
          </div>
        </div>

        <Button
          type="submit"
          className="bg-[#50c878] w-full text-center text-white text-xl font-medium leading-7 rounded justify-center items-center my-4 border-none outline-none focus:outline-none"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Login"}
        </Button>
      </form>

      <Link
        to="/signup"
        className="text-[#313d31] text-base font-normal flex justify-center w-full leading-normal pb-10"
      >
        Don't have an account yet? &nbsp;
        <span className="text-[#50c878] text-base font-bold leading-normal">
          Sign up
        </span>
      </Link>
    </div>
  );
};

export default LoginForm;
