import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TabButton from "../components/atoms/TabButton";
import BasicInformation from "../components/organisms/customer/BasicInformation";
import Status from "../components/organisms/customer/Status";
import CreditHistory from "../components/organisms/customer/CreditHistory";
import Discount from "../components/organisms/customer/Discount";
import AdminActions from "../components/organisms/customer/AdminActions";
import SavedSearches from "../components/organisms/customer/SavedSearches";
import Suppression from "../components/organisms/customer/Supperssion";
import CustomPurchase from "../components/organisms/customer/CustomPurchase";
import Faudelnts from "../components/organisms/customer/Faudelnts";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerDetails } from "../redux/slices/getAllCustomersSlice";
import SearchHistory from "../components/organisms/customer/SearchHistory";
import Export from "../components/organisms/customer/Export";
import ManageSubscription from "../components/organisms/customer/ManageSubscription";
import OnBoardingInfo from "../components/organisms/customer/OnBoardingInfo";

const CustomerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: customerId } = useParams(); // Get the customerId from the route

  const { customerDetails, loading, error } = useSelector(
    (state) => state.customers
  );

  // Extract tab from URL, fallback to 'company' if no tab specified
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "information";
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const customerUserName = customerDetails?.data?.customer?.name;

  useEffect(() => {
    dispatch(fetchCustomerDetails(customerId));
  }, []);

  const tabs = [
    {
      label: "Basic Information",
      id: "information",
      content: (
        <BasicInformation customerDetails={customerDetails} loading={loading} />
      ),
    },
    {
      label: "Onboading Detail",
      id: "onboarding",
      content: (
        <OnBoardingInfo customerDetails={customerDetails} loading={loading} />
      ),
    },
    {
      label: "Plan status + Purchase history",
      id: "status",
      content: <Status />,
    },
    {
      label: "Manage Subscription",
      id: "manage_subscription",
      content: <ManageSubscription />,
    },
    {
      label: "Company Credits History",
      id: "credits",
      content: <CreditHistory />,
    },
    {
      label: "Discount information",
      id: "Discount",
      content: <Discount />,
    },
    {
      label: "Search History",
      id: "search-history",
      content: <SearchHistory />,
    },
    {
      label: "Admin actions",
      id: "actions",
      content: <AdminActions customerDetails={customerDetails} />,
    },
    {
      label: "Saved Searches",
      id: "savedsearches",
      content: <SavedSearches customerDetails={customerDetails} />,
    },
    {
      label: "Exports",
      id: "Exports",
      content: <Export />,
    },
    // {
    //   label: "Suppression Lists",
    //   id: "suppression",
    //   content: <Suppression />,
    //   to: "/admin/create-suppersionlist",
    // },
    {
      label: "Custom Purchases",
      id: "purchases",
      content: <CustomPurchase />,
      to: `/admin/create-custompurchase/${customerId}`,
    },
    {
      label: "Fraudulent?",
      id: "Fraudulent",
      content: <Faudelnts />,
    },
  ];

  // Update URL when the tab changes
  useEffect(() => {
    navigate(`?tab=${selectedTab}`, { replace: true });
  }, [selectedTab, navigate]);
  // Find the currently selected tab's object
  const currentTab = tabs.find((tab) => tab.id === selectedTab);
  return (
    <div>
      <div className="px-6 py-6 w-full">
        <div className="flex justify-between items-center  mb-2 w-full ">
          <h3 className="font-bold text-xl text-text-color ">
            {customerUserName ?? ""}
          </h3>
          {selectedTab === "suppression" || selectedTab === "purchases" ? (
            <Link
              to={currentTab?.to}
              className="bg-green-500 hover:bg-green-600 text-white font-medium px-4 py-2 rounded"
            >
              Create {currentTab?.label} link
            </Link>
          ) : null}
        </div>

        <div className="mt-8">
          <div>
            <div className="mb-6 ">
              <nav className="flex space-x-6 border-b w-full overflow-x-scroll whitespace-nowrap max-w-[82vw] tabs">
                {tabs.map((tab) => (
                  <TabButton
                    key={tab.id}
                    label={tab.label}
                    isActive={selectedTab === tab.id}
                    onClick={() => setSelectedTab(tab.id)}
                  />
                ))}
              </nav>
            </div>
            {tabs.map(
              (tab) =>
                selectedTab === tab.id && <div key={tab.id}>{tab.content}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
