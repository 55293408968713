import React from "react";
import Checkbox from "../atoms/Checkbox";
import FilterItem from "./FilterItem";
import TechData from "../../assets/data/tech_stack_keys.json";
const AdditionalFilters = ({
  filters,
  onFilterChange,
  Stacks,
  showSelectedValues,
  selectedFilters,
  setSelectedFilters,
  tech_stack,
  handleTechChange,
}) => {
  const handleCheckboxChange = (grade, checked) => {
    // Check if the checkbox being unchecked is the only one checked
    const isLastChecked =
      (grade === "emailGradeA" && !selectedFilters.emailGradeB) ||
      (grade === "emailGradeB" && !selectedFilters.emailGradeA);

    // If the last checkbox is being unchecked, don't allow the change
    console.log({ [grade]: checked });
    if (isLastChecked) return;
    setSelectedFilters((prev) => ({
      ...prev,
      [grade]: checked,
    }));

    // onFilterChange(grade, !filters[grade]);
  };
  console.log("searchParams", selectedFilters.emailGradeB);
  return (
    <>
      <div className="flex flex-col gap-3 mb-4">
        <div className="w-full flex flex-col gap-3">
          <FilterItem
            label="Tech Stacks"
            options={Stacks}
            placeholder="Enter Tech Stack or select"
            value={
              showSelectedValues
                ? selectedFilters.tech_stack?.map((label) => ({
                    value: label,
                    label: label,
                  }))
                : []
            }
            onChange={(selectedTitles) => {
              setSelectedFilters((prev) => ({
                ...prev,
                tech_stack: selectedTitles,
              }));
              handleTechChange(selectedTitles);
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-3">
          <label className="block text-sm font-medium text-gray-700">
            Company Size
          </label>
          <div className="flex gap-3 w-full">
            <input
              type="number"
              value={selectedFilters.minEmployees}
              className="w-full text-[#313d31] h-[2.4rem] border rounded-[4px] border-[#cccccc] px-2 outline-[#50c878]"
              placeholder="Min employees"
              onChange={(e) => {
                // onFilterChange("minEmployees", e.target.value);
                setSelectedFilters((prev) => ({
                  ...prev,
                  minEmployees: e.target.value,
                }));
              }}
              maxLength={9}
              onInput={(e) => {
                if (e.target.value.length > 9) {
                  e.target.value = e.target.value.slice(0, 9);
                }
              }}
            />
            <input
              type="number"
              value={selectedFilters.maxEmployees}
              className="w-full text-[#313d31] h-[2.4rem] border rounded-[4px] border-[#cccccc] px-2 outline-[#50c878]"
              placeholder="Max employees"
              onChange={(e) =>
                setSelectedFilters((prev) => ({
                  ...prev,
                  maxEmployees: e.target.value,
                }))
              }
              maxLength={9}
              onInput={(e) => {
                if (e.target.value.length > 10) {
                  e.target.value = e.target.value.slice(0, 10);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 mb-4">
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700">
            Revenue
          </label>
          <input
            type="number"
            value={selectedFilters.minRevenue}
            className="w-full text-[#313d31] h-[2.4rem] border rounded-[4px] border-[#cccccc] px-2 outline-[#50c878]"
            placeholder="Min revenue"
            onChange={(e) =>
              setSelectedFilters((prev) => ({
                ...prev,
                minRevenue: e.target.value,
              }))
            }
            onInput={(e) => {
              if (e.target.value.length > 12) {
                e.target.value = e.target.value.slice(0, 12);
              }
            }}
          />
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700">
            {""} &nbsp;
          </label>
          <input
            type="number"
            value={selectedFilters.maxRevenue}
            className="w-full text-[#313d31] h-[2.4rem] border rounded-[4px] border-[#cccccc] px-2 outline-[#50c878]"
            placeholder="Max revenue"
            onChange={(e) =>
              setSelectedFilters((prev) => ({
                ...prev,
                maxRevenue: e.target.value,
              }))
            }
            onInput={(e) => {
              if (e.target.value.length > 12) {
                e.target.value = e.target.value.slice(0, 12);
              }
            }}
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Email Grade
        </label>
        <div className="flex gap-3 items-center my-1">
          <Checkbox
            id="emailGradeA"
            checked={selectedFilters?.emailGradeA}
            onChange={(e) =>
              handleCheckboxChange("emailGradeA", e.target.checked)
            }
          />
          <label
            htmlFor="emailGradeA"
            className="text-[#838b83] text-sm font-normal font-['DM Sans'] leading-tight"
          >
            A (97%+ deliverable)
          </label>
        </div>
        <div className="flex gap-3 items-center my-1">
          <Checkbox
            id="emailGradeB"
            checked={selectedFilters?.emailGradeB}
            onChange={(e) =>
              handleCheckboxChange("emailGradeB", e.target.checked)
            }
          />
          <label
            htmlFor="emailGradeB"
            className="text-[#838b83] text-sm font-normal font-['DM Sans'] leading-tight"
          >
            B (85%+ deliverable)
          </label>
        </div>
      </div>
    </>
  );
};

export default AdditionalFilters;
