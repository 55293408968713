import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET, POST } from "../../../services/apiServices";
import toast from "react-hot-toast";
import { Button } from "../../atoms/buttons";
import Popup from "../../ui/Popup";
import Spinner from "../../atoms/Spinner";

export default function ManageSubscription() {
  const { id: customerId } = useParams();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [sortedPlans, setSortedPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUserPlan = async () => {
    try {
      const response = await GET(
        `/customer/admin/customer-subscription/${customerId}`
      );
      setSubscriptionData(response);

      if (response?.hasSubscription) {
        const currentPlan = response?.data?.find((plan) => plan.isCurrentPlan);
        setSelectedPlan(currentPlan?.priceId);
      }

      // Sort plans by priceShow in ascending order
      const sorted = response?.data?.sort((a, b) => a.priceShow - b.priceShow);
      setSortedPlans(sorted);
    } catch (error) {
      toast.error("Failed to fetch subscription data");
    }
  };

  const handlePlanChange = (priceId) => {
    setSelectedPlan(priceId);
  };

  const handleSaveSubscription = async () => {
    if (!selectedPlan) return;
    try {
      setLoading(true);
      await POST(`/customer/admin/upgrade-dowgrade/${customerId}`, {
        newPriceId: selectedPlan,
      });
      toast.success("Plan updated successfully");
      setShowPopup(false);
      fetchUserPlan(); // Refresh the subscription data after update
    } catch (error) {
      toast.error("Failed to update the plan");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserPlan();
  }, []);

  if (!subscriptionData) {
    return <div className="flex justify-center items-center">Loading...</div>;
  }

  if (!subscriptionData.hasSubscription) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-600 text-lg">
          The user is not subscribed to any plan.
        </p>
      </div>
    );
  }

  return (
    <div className="px-4">
      <h2 className="text-2xl font-bold mb-4">Manage Subscription</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {sortedPlans.map((plan) => (
          <div
            key={plan.id}
            className={`p-4 rounded-lg shadow-md cursor-pointer ${
              plan.bgColor
            } ${
              selectedPlan === plan.priceId ? "border-2 border-green-500" : ""
            }`}
            onClick={() => handlePlanChange(plan.priceId)}
          >
            <img
              src={plan.image}
              alt={plan.title}
              className="w-16 h-16 mb-4 mx-auto"
            />
            <h3
              className={`text-xl font-semibold ${plan.textColor} text-center`}
            >
              {plan.title}
            </h3>
            <p className={`text-sm ${plan.textColor} text-center`}>
              {plan.description}
            </p>
            <ul className={`mt-4 text-sm ${plan.textColor}`}>
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center gap-2">
                  <img src={plan.icon} alt="Feature" className="w-4 h-4" />
                  {feature}
                </li>
              ))}
            </ul>
            <div className="mt-4 text-center">
              <label className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  name="subscriptionPlan"
                  value={plan.priceId}
                  checked={selectedPlan === plan.priceId}
                  onChange={() => handlePlanChange(plan.priceId)}
                  className="form-radio"
                />
                <span className={`${plan.textColor}`}>{plan.buttonText}</span>
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-center">
        <Button
          className="bg-green-500 text-white rounded-md px-6 py-2 hover:bg-green-600 disabled:opacity-50"
          onClick={() => setShowPopup(true)}
          disabled={loading}
        >
          Save Changes
        </Button>
      </div>
      <Popup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        title="Confirm Plan Change"
      >
        <div className="flex flex-col gap-3 items-end">
          <p className="text-gray-600">
            Are you sure you want to update the plan?
          </p>
          <Button
            className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 disabled:opacity-50"
            onClick={handleSaveSubscription}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Confirm"}
          </Button>
        </div>
      </Popup>
    </div>
  );
}
